import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import FilterAndInfoPanel from "./FilterAndInfoPanel";
import MapView from "./MapView";
import TableView from "./TableView";
import "./App.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap, faTable} from "@fortawesome/free-solid-svg-icons";

const App = () => {
  const [searchParams] = useSearchParams();
  const initialDate = searchParams.get("date") || new Date().toISOString().split("T")[0];
  const initialPrefix = searchParams.get("prefix") || "";
  const [startDate, setStartDate] = useState(new Date(initialDate));
  const [searchTerm, setSearchTerm] = useState(initialPrefix || "");
  const [view, setView] = useState("map");
  const [dataResponse, setDataResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anycastSites, setAnycastSites] = useState({
    prefix: "",
    anycast: false,
    characterization: {},
    instances: [],
  });
  const [isValidIP, setIsValidIP] = useState(false);

  const handleNextDay = () => {
    const nextDay = new Date(startDate);
    nextDay.setDate(startDate.getDate() + 1);
    setStartDate(nextDay);
  };

  const handlePreviousDay = () => {
    const previousDay = new Date(startDate);
    previousDay.setDate(startDate.getDate() - 1);
    setStartDate(previousDay);
  };

  useEffect(() => {
    if (dataResponse) {
      const prefix = dataResponse.prefix;
      const anycast = dataResponse.anycast;
      const characterization = dataResponse.characterization;

      const newAnycastSites = {
        prefix: prefix,
        anycast: anycast,
        characterization: characterization,
        instances: characterization?.iGreedyICMPv4?.positions?.map((item) => ({
          city: item.city,
          code_country: item.code_country,
          id: item.id,
          position: [item.latitude, item.longitude],
        })) || [],
      };

      setAnycastSites(newAnycastSites);
    } else {
      setAnycastSites((prev) => ({
        ...prev,
        prefix: "",
        anycast: false,
        characterization: {},
        instances: [],
      }));
    }
  }, [dataResponse]);

  return (
      <div className="container">
        {loading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
        )}
        <FilterAndInfoPanel
            startDate={startDate}
            setStartDate={setStartDate}
            handlePreviousDay={handlePreviousDay}
            handleNextDay={handleNextDay}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            view={view}
            setView={setView}
            handleApiResponse={setDataResponse}
            setLoading={setLoading}
            isValidIP={isValidIP}
            setIsValidIP={setIsValidIP}
            initialDate={initialDate}
            initialPrefix={initialPrefix}
            anycastSites={anycastSites}
        />
        {anycastSites.anycast && (
        <div className="content">
          <h2>Geolocation Data</h2>
        <div className="toggle-container">
          <label className={`toggle-switch ${view === "table" ? "on" : ""}`}>
            <input type="checkbox" checked={view === "table"}
                   onChange={() => setView(view === "map" ? "table" : "map")}/>
            <span className="toggle-slider"></span>
            <FontAwesomeIcon icon={faMap} className="toggle-icon map-icon"/>
            <FontAwesomeIcon icon={faTable} className="toggle-icon table-icon"/>
          </label>
        </div>
        </div>
        )}
        {anycastSites.anycast && (
        <div className="content">
          {view === "map" ? (
              <MapView instances={anycastSites.instances} key={JSON.stringify(anycastSites.instances)}/>
          ) : (
              <TableView instances={anycastSites.instances}/>
          )}
        </div>
        )}
      </div>

  );
};

export default App;