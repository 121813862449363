import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const CharacterizationTable = ({ characterization }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    if (!characterization || Object.keys(characterization).length === 0) {
        return null;
    } else {
        const hasV4Key = Object.keys(characterization).some(key => key.includes('v4'));
        var rows = [];
        rows = hasV4Key
            ? [
                { protocol: 'Anycast-based ICMPv4', data: characterization?.MAnycastICMPv4 },
                { protocol: 'Anycast-based TCPv4', data: characterization?.MAnycastTCPv4 },
                { protocol: 'Anycast-based UDPv4', data: characterization?.MAnycastUDPv4 },
                { protocol: 'GCD-based ICMPv4', data: characterization?.iGreedyICMPv4 },
                { protocol: 'GCD-based TCPv4', data: characterization?.iGreedyTCPv4 }
            ]
            : [
                { protocol: 'Anycast-based ICMPv6', data: characterization?.MAnycastICMPv6 },
                { protocol: 'Anycast-based TCPv6', data: characterization?.MAnycastTCPv6 },
                { protocol: 'Anycast-based UDPv6', data: characterization?.MAnycastUDPv6 },
                { protocol: 'GCD-based ICMPv6', data: characterization?.iGreedyICMPv6 },
                { protocol: 'GCD-based TCPPv6', data: characterization?.iGreedyTCPv6 }
            ];
    }

    return (
        <div>
            <table className="characterization-table">
                <thead onClick={() => setIsCollapsed(!isCollapsed)} style={{ cursor: 'pointer' }}>
                <tr>
                    <th>Detector
                        {isCollapsed ? (
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '10px' }} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginLeft: '10px' }} />
                        )}
                    </th>
                    <th>Anycast</th>
                    <th>
                        Instances
                    </th>
                </tr>
                </thead>
                {!isCollapsed && (
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td>{row.protocol}</td>
                            <td>{row.data?.anycast !== undefined ? (row.data.anycast ? 'Yes' : 'No') : 'N/A'}</td>
                            <td>{row.data?.instances ?? 'N/A'}</td>
                        </tr>
                    ))}
                    </tbody>
                )}
            </table>
        </div>
    );
};

export default CharacterizationTable;