import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const customMarker = new L.Icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const MapView = ({ instances }) => {
    const [minZoom, setMinZoom] = useState(1.5);
    const [key, setKey] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 480) {
                setMinZoom(0.4);
            } else if (window.innerWidth <= 768) {
                setMinZoom(0.9);
            } else {
                setMinZoom(1.5);
            }
            setKey(prevKey => prevKey + 1); // Update key to force re-render
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial minZoom based on current window size

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="map-section">
            <MapContainer
                key={key}
                minZoom={minZoom}
                center={[20, 0]}
                zoom={minZoom}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                />
                {instances.map((site) => (
                    <Marker key={site.id} position={site.position} icon={customMarker}>
                        <Popup>
                            <div>
                                <strong>{site.city}</strong> <strong>({site.code_country})</strong>
                                <br />
                                <strong>ID: {site.id}</strong>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};

export default MapView;