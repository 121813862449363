import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faTable, faSearch, faCalendarAlt, faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import CharacterizationTable from './CharacterizationTable';
import { useNavigate } from 'react-router-dom';

const FilterAndInfoPanel = ({ startDate, setStartDate, handlePreviousDay, handleNextDay, searchTerm = '', setSearchTerm, view, setView, handleApiResponse, setLoading, isValidIP, setIsValidIP, initialDate, initialPrefix, anycastSites }) => {
    const [response, setResponse] = useState(null);
    const [maxCensusV4Date, setMaxCensusV4Date] = useState(null);
    const [maxCensusV6Date, setMaxCensusV6Date] = useState(null);
    const [minCensusV4Date, setMinCensusV4Date] = useState(null);
    const [minCensusV6Date, setMinCensusV6Date] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://api.manycast.net/latest')
            .then(res => res.json())
            .then(data => {
                setMaxCensusV4Date(data.max_census_v4_date);
                setMaxCensusV6Date(data.max_census_v6_date);
                setMinCensusV4Date(data.min_census_v4_date);
                setMinCensusV6Date(data.min_census_v6_date);
            })
            .catch(error => console.error('Error fetching latest dates:', error));
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    };

    const validateIP = (ip) => {
        const [address, prefix] = ip.split('/');
        if (ip.includes('.')) {
            const segments = address.split('.');
            return (
                segments.length === 4 &&
                segments.every((seg) => /^\d+$/.test(seg) && parseInt(seg, 10) >= 0 && parseInt(seg, 10) <= 255) &&
                (!prefix || (parseInt(prefix, 10) >= 0 && parseInt(prefix, 10) <= 32))
            );
        } else if (ip.includes(':')) {
            const segments = address.split(':');
            return (
                segments.length <= 8 &&
                segments.every((seg) => seg === '' || /^[0-9a-fA-F]{1,4}$/.test(seg)) &&
                (!prefix || (parseInt(prefix, 10) >= 0 && parseInt(prefix, 10) <= 128))
            );
        }
        return false;
    };

    const handleIPChange = (e) => {
        const ip = e.target.value;
        setSearchTerm(ip);
        setIsValidIP(validateIP(ip));
    };

    useEffect(() => {
        const fetchData = (date, prefix) => {
            setLoading(true);
            const formattedDate = startDate.toISOString().split("T")[0];
            let apiUrl = "";

            if (searchTerm.includes(":")) {
                apiUrl = `https://api.manycast.net/census/v6/${formattedDate}/${searchTerm}`;
            } else {
                apiUrl = `https://api.manycast.net/census/v4/${formattedDate}/${searchTerm}`;
            }

            fetch(apiUrl)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Data not found in the database");
                    }
                    return res.json();
                })
                .then((data) => {
                    handleApiResponse(data);
                    setError(null);
                })
                .catch((error) => {
                    console.error("Error fetching the API:", error);
                    setError(error.message);
                    handleApiResponse({
                        prefix: "",
                        characterization: {},
                        instances: []
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        const isValid = validateIP(searchTerm);
        setIsValidIP(isValid);
        if (isValid) {
            fetchData(startDate, searchTerm);
        }
    }, [searchTerm, startDate]);

    const isMaxDate = () => {
        const formattedDate = startDate.toISOString().split("T")[0];
        if (searchTerm.includes(":")) {
            return formattedDate === maxCensusV6Date;
        } else {
            return formattedDate === maxCensusV4Date;
        }
    };

    const isMinDate = () => {
        const formattedDate = startDate.toISOString().split("T")[0];
        if (searchTerm.includes(":")) {
            return formattedDate === minCensusV6Date;
        } else {
            return formattedDate === minCensusV4Date;
        }
    };

    return (
        <div className="filter-and-info-panel">
            <div className="info-section">
                <div className="selected-date">
                    <p><strong>Census Date:</strong></p>
                    {!isMinDate() && (
                        <button onClick={handlePreviousDay} style={{marginLeft: '10px', marginRight: '10px'}}>
                            <FontAwesomeIcon icon={faBackward}/>
                        </button>
                    )}
                    <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        onKeyDown={(e) => e.preventDefault()}
                        minDate={searchTerm.includes(":") ? new Date(minCensusV6Date) : new Date(minCensusV4Date)}
                        maxDate={searchTerm.includes(":") ? new Date(maxCensusV6Date) : new Date(maxCensusV4Date)}
                        customInput={
                            <button className="calendar-icon">
                                <FontAwesomeIcon icon={faCalendarAlt} size="lg"/>
                                <span>{startDate.toDateString()}</span>
                            </button>
                        }
                    />
                    {!isMaxDate() && (
                        <button onClick={handleNextDay}>
                            <FontAwesomeIcon icon={faForward}/>
                        </button>
                    )}
                </div>
                <div className="filter-item">
                    <label><strong>IP:</strong> </label>
                    <input type="text" placeholder="Prefix" onChange={handleIPChange} value={searchTerm || ''}/>
                    {isValidIP ? " ✅" : " ❌"}
                </div>
                <p><strong>Prefix:</strong> {anycastSites?.prefix}</p>
                <p>
                    <strong>Type:</strong> {anycastSites?.anycast !== undefined ? (anycastSites.anycast ? 'Anycast' : 'Unicast') : 'N/A'}
                </p>
                {error && <div className="error-message">{'⚠️' + error}</div>}
                <CharacterizationTable characterization={anycastSites.characterization}/>
            </div>
        </div>
    );
};

export default FilterAndInfoPanel;